<template>
  <div class="px-3 mt-5">
    <div class="d-block d-lg-flex justify-content-between px-3 mt-5 mt-lg-0">
      <div class="col-12 col-lg-6 mt-0 mt-lg-5">
        <h2 class="mt-4">Safe Haven For Developers</h2>
        <p class="mt-4">
          Appmart Developers sandbox is well-documented API that is easy to
          build and integrate to your new and exciting projects ranging from:
        </p>

        <div class="mt-5">
          <div class="card shadow border-0">
            <div class="card-body d-flex pt-4">
              <img
                src="@/assets/images/check.svg"
                alt="icons"
                width="16"
                height="16"
                class="me-3 mt-2"
              />
              <p>
                Make or collect on-off or reoccurring payments from your online
                services, web, mobile app or e-commerce website.
              </p>
            </div>
          </div>

          <div class="card shadow border-0 mt-5">
            <div class="card-body d-flex pt-4">
              <img
                src="@/assets/images/check.svg"
                alt="icons"
                width="16"
                height="16"
                class="me-3 mt-2"
              />
              <p>Create merchant POS terminal for financial services.</p>
            </div>
          </div>
        </div>

        <button class="btn btn-dance mt-5">API Docs</button>
      </div>

      <div class="col-12 col-lg-5 my-5 d-flex justify-content-end">
        <img
          class="img"
          src="@/assets/images/code_snippet.png"
          alt="developers"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Developers",
};
</script>
<style scoped>
h6 {
  color: #0b2238;
  font-size: 1rem;
}
h3 {
  color: #0b2238;
  font-size: 1.5rem;
  position: relative;
}
.btn-dance {
  background: #ed342b;
  color: #ffffff;
  border-radius: 0.5rem;
  padding: 0 3rem;
  height: 54px;
}
.btn-dance:hover {
  color: #ffffff;
}
p {
  font-size: 1rem;
  color: #9da6af;
  text-align: justify;
}

.card {
  min-height: 88px !important;
}
.card-body p {
  font-size: 1rem;
  color: #0b2238;
  font-weight: 400;
  line-height: 1.5;
}
.img {
  width: 83%;
  margin: 0 auto;
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
</style>
