<template>
  <div class="px-3 mt-5 pt-3 pb-2">
    <div class="d-block d-lg-flex justify-content-between px-3 my-5">
      <div class="col-12 col-lg-7 mb-0 mb-lg-3">
        <h2>More payment channels just for you</h2>
        <p class="mt-4">
          A secure, flexible and easy way to make and receive payments for
          businesses and individuals across Nigeria, Africa and the world.
        </p>

        <div>
          <ul>
            <li v-for="(pay, i) in payments" :key="i">
              <img
                src="@/assets/images/check.svg"
                alt="icons"
                width="18"
                height="18"
                class="me-2"
              />
              <span>{{ pay }}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12 col-lg-5 my-5 d-flex justify-content-end">
        <img class="img" src="@/assets/images/cards.svg" alt="payment cards" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurProducts",

  data() {
    return {
      payments: [
        "Mobile Money",
        "Bank Transfer",
        "Bank Account",
        "POS",
        "USSD",
        "Visa QR",
      ],
    };
  },
};
</script>
<style scoped>
h2 {
  font-weight: bold;
  color: #ffe7aa;
  line-height: 38px;
  font-size: 1.875rem;
}
p {
  font-size: 1rem;
  color: #9da6af;
}
.img {
  width: 100%;
  margin: 0 auto;
}
li {
  list-style: none;
  color: #ffffff;
  margin-top: 1.438rem;
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
</style>
