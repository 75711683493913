<template>
  <div class="px-3 mt-5 mb-5">
    <div class="d-block d-lg-flex justify-content-between px-3 mt-5">
      <div class="col-12 col-lg-4 pt-7">
        <h6>Pricing Plan</h6>
        <h2 class="mt-4">Simple pricing for everyone</h2>

        <div class="d-flex btns mt-5">
          <button
            @click="setPlan('local')"
            :class="`btn btn-near ${plan == 'local' ? 'active' : ''}`"
          >
            Local
          </button>
          <button
            @click="setPlan('world')"
            :class="`btn btn-near ${plan == 'world' ? 'active' : ''}`"
          >
            International
          </button>
        </div>
      </div>

      <div class="col-12 col-lg-7 mt-4 mt-lg-5 d-flex justify-content-center">
        <div v-if="plan == 'local'" class="plans local">
          <h4>Local</h4>
          <ul>
            <li>1.5% capped ₦2,000 transaction fee</li>
            <li>N80 processing fee</li>
            <li>Accepts all bank cards, Visa, Mastercard and Verve.</li>
          </ul>
          <button class="btn btn-dance">
            <span>Start 14-Days Trial</span>
          </button>
        </div>

        <div v-else class="plans world">
          <h4>Internaltional</h4>
          <ul>
            <li>3.5% transaction fee</li>
            <li>$1.5 processing fee</li>
            <li>Payment can be received from anywhere in the world</li>
          </ul>
          <button class="btn btn-dance">
            <span>Start 14-Days Trial</span>
          </button>
        </div>
      </div>
    </div>

    <div class="d-block px-3 mt-5 table-responsive">
      <table class="table table-borderless mt-4">
        <thead>
          <tr>
            <th></th>
            <th>API</th>
            <th>Appmart store</th>
            <th>Mobile</th>
            <th>e-Commerce</th>
            <th>Unbanked</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Biopay</td>
            <td>
              <span>
                <img
                  width="24"
                  height="24"
                  src="@/assets/images/check.svg"
                  alt="icons"
                />
              </span>
            </td>
            <td>
              <span>
                <img
                  width="24"
                  height="24"
                  src="@/assets/images/check.svg"
                  alt="icons"
                />
              </span>
            </td>
            <td></td>
            <td>
              <span>
                <img
                  width="24"
                  height="24"
                  src="@/assets/images/check.svg"
                  alt="icons"
                />
              </span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>PayHandle</td>
            <td></td>
            <td></td>
            <td>
              <span>
                <img
                  width="24"
                  height="24"
                  src="@/assets/images/check.svg"
                  alt="icons"
                />
              </span>
            </td>
            <td></td>
            <td>
              <span>
                <img
                  width="24"
                  height="24"
                  src="@/assets/images/check.svg"
                  alt="icons"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "Pricing",

  data() {
    return {
      plan: "local",
    };
  },

  methods: {
    setPlan(plan) {
      this.plan = plan;
    },
  },
};
</script>
<style scoped>
h2 {
  font-weight: bold;
  color: #0b2238;
  line-height: 38px;
  font-size: 1.875rem;
}
h6 {
  color: #0b2238;
  font-size: 1rem;
}
.btn-dance {
  color: #0b2238;
  background: #ffffff;
  margin-top: 2.5rem;
  padding: 0.7rem 2rem;
}
.btns {
  background-color: rgba(22, 93, 245, 0.05);
  border-radius: 8px;
  padding: 0.65rem;
  width: max-content;
}
.btn-near {
  color: #ed342b;
  padding: 0.5rem 1rem;
}
.btn-near:focus {
  outline: none;
  box-shadow: none;
}
.btn-near.active {
  color: #ffffff;
  background: #ed342b;
}
.pt-7 {
  padding-top: 1rem;
}
ul {
  padding-inline-start: 20px;
  margin-top: 2rem;
}
li {
  margin-top: 0.5rem;
}
.plans {
  height: 370px;
  width: 350px;
  border-radius: 8px;
  color: #ffffff;
  padding: 2rem 3rem;
}
.plans.local {
  background: #ed342b;
  background-image: url("../../assets/images/bg-cubic.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  background-blend-mode: multiply;
}
.plans.world {
  background: #0b2238;
  background-image: url("../../assets/images/bg-cubic.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  background-blend-mode: multiply;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
table > thead {
  height: 59px;
  vertical-align: middle !important;
  background: #fafafc;
  border-collapse: collapse;
  color: #6c7a87;
}
th {
  font-weight: 400 !important;
}
table > thead tr th td {
  border-collapse: collapse;
}
td {
  padding: 1rem 1rem !important;
  box-shadow: inset 0px 3px #fafafc !important;
}
thead {
  font-size: 0.813rem;
}
td span {
  width: 50px;
  height: 50px;
  border-radius: 20px;
  background-color: rgba(23, 231, 155, 0.1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  thead {
    font-size: 1rem;
  }
  .pt-7 {
    padding-top: 8rem;
  }
}
</style>
