<template>
<div class="cookiepolicy">
    <section id="top">
        <Header id="header" :scrolled="true" />
    </section>
    <section id="moreInfo">
        <div class="content-wrap">
            <h4 class="heading">COOKIES POLICY</h4>
            <strong>Last updated September, 2022</strong>
            <p></p>
            <h5>INTRODUCTION</h5>
            <p>
                We at AppMart.ng use cookies to ensure you get the best experience when you use our service. This cookie policy provides you with clear and comprehensive information about the cookies we use and the purpose for using those cookies on this platform.
            </p>
            <p>
                Please read the following carefully to understand our policies and practices regarding the use of cookies on our platform. <br>
                By using or accessing our platform, you agree to this Cookie Policy. This Policy may change from time to time and your continued use of the platform is deemed acceptance of such changes, so please check the policy periodically for such changes.
            </p>
            <h5>CONSENT</h5>
            <p>
                You consent to placement of cookies on your browser by us and our third-party service providers. Please read this policy carefully for details about why we use cookies and the information they collect from and about you. <br>
                If you do not wish to accept cookies in connection with your use of our services, you will need to delete and block or disable cookies via your browser settings. Please note that doing so will affect the functionality of this platform and may prevent you from accessing certain features of this platform.
            </p>
            <h5>WHAT ARE COOKIES</h5>
            <p>
                A cookie is a small file of letters and numbers that may be stored on your browser or the hard drive of your computer when you visit our platform. Cookies contain information about your visit and allows the platform to remember your actions or preferences overtime. Most browsers support cookies, but users can set their browsers to decline them and can delete them whenever they like.
            </p>
            <h5>WHY WE USE COOKIES</h5>
            <p>
                Cookies serve many different functions. We use cookies to allow us tailor our services to your needs and deliver a better and more personalized service. Cookies help us to improve the performance of our platform by enabling us to:
            </p>
            <ul>
                <li>Help you navigate between pages on the platform efficiently</li>
                <li>Protect your security</li>
                <li>Remember information about your references and recognize you when you return to our platform</li>
                <li>Allow us to customize our services according to your individual interests</li>
                <li>Measure how people are using our platform in other to improve our services and browsing experience</li>
                <li>Personalize advertising and make the content more relevant for you</li>
                <li>Make our platform easier to use</li>
                <li>Generally give you a better experience.</li>
            </ul>
            <p>Cookies are not unsafe or in themselves a threat to your online privacy, as we do not store sensitive information and we never sell your details to any third parties. </p>
            <h5>DIFFERENT TYPES OF COOKIES</h5>
            <p>
                <strong>First party cookies-</strong>these are our own cookies set by our platform, controlled by us and used to provide information about the usage of our platform.
            </p>
            <p>
                <strong>Third party cookies-</strong> these are cookies from any other domain. They may be placed on your device when you visit other platforms that operate certain services we offer. These cookies allow third party platforms to collect and track certain information about you. These cookies can also be manually disabled in your browser.
            </p>
            <h5>HOW LONG DO COOKIES STAY ON YOUR COMPUTER</h5>
            <div>
                Cookies that are used on our platform may be either session cookies or persistent cookies.
                <p>
                    <strong>Session cookies-</strong> these are temporary that remain on your device until you leave the platform
                </p>
                <p>
                    <strong>Persistent cookies-</strong> these are stored on your hard drive until you delete them or they reach their expiry date.
                </p>
            </div>

            <h5>PRIVACY POLICY</h5>
            <p>
                This Cookies Policy forms part of and is incorporated into our Privacy Policy. To see more information on how we use data collected by cookies, please see the Privacy Policy. By using this platform, you agree to be bound by this Cookies and Privacy Policies.
            </p>
        </div>
    </section>
    <section id="footer">
        <Footer />
    </section>
</div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
    components: {
        Header,
        Footer,
    },

    data() {
        return {
            scrollHeight: 0,
        };
    },

    mounted() {
        window.onscroll = function () {
            setFixedTop();
        };

        // Get the header
        const header = document.getElementById("header");

        // Get the offset position of the navbar
        const sticky = header.offsetTop;

        // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
        const setFixedTop = () => {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        };
    },

}
</script>

<style>
.mt {
    margin-top: .6rem;
}

.cookiepolicy {
    height: 100%;
}

.heading {
    text-align: center;
    font-size: 2rem;
}

.moreInfo{
  margin: 0 auto;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.content-wrap {
    margin-top: 2rem;
    text-align: justify;
}

.about::before {
    content: "";
    background-image: url("../assets/images/line-cc.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    pointer-events: none;
    opacity: 0.03;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    #moreInfo {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}
</style>
