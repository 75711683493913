import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const getStoreUser = () => {
  const encryptUser = localStorage.getItem(process.env.VUE_APP_user);

  if (encryptUser) return JSON.parse(atob(encryptUser));

  return null;
};

export default createStore({
  plugins: [createPersistedState()],

  state: {
    user: getStoreUser(),
  },

  getters: {
    username: (state) => {
      return state.user
        ? state.user.firstName + " " + state.user.lastName
        : null;
    },
    userEmail: (state) => {
      return state.user ? state.user.email : null;
    },
  },

  mutations: {
    saveUser(state, user) {
      state.user = user;
    },
    clearUserDetails(state) {
      state.user = null;
    },
  },

  actions: {
    saveUserInfo({ commit }, { user, token }) {
      const data = JSON.stringify(user);
      const encodedString = btoa(data);
      localStorage.setItem(process.env.VUE_APP_user, encodedString);
      localStorage.setItem(process.env.VUE_APP_tokenName, token);

      commit("saveUser", user);
    },

    refreshUser({ commit }) {
      const userdata = localStorage.getItem(process.env.VUE_APP_user);
      const retrived = JSON.parse(atob(userdata));

      commit("saveUser", retrived);
    },

    logout({ commit }) {
      localStorage.removeItem(process.env.VUE_APP_user);
      localStorage.removeItem(process.env.VUE_APP_tokenName);

      commit("clearUserDetails");
    },
  },
});
