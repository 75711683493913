<template>
  <div class="pe-3 mt-5 ps-6">
    <div class="d-block d-lg-flex justify-content-between px-3 my-5">
      <div class="col-12 col-lg-7 mt-0 mt-lg-5">
        <h1>Global Payment Solution for Businesses and individuals.</h1>
        <p class="mt-4">
          A secure, flexible and easy way to make and receive payments
          <br class="d-none d-lg-block" />
          for businesses and individuals across Nigeria, Africa and the world.
        </p>
        <router-link to="/signup" class="btn btn-dance mt-4">
          Create Account
        </router-link>
      </div>

      <div class="col-12 col-lg-4 my-5 d-flex justify-content-end">
        <img src="@/assets/images/biopay.svg" alt="biopay" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Intro",
};
</script>
<style scoped>
.ps-6 {
  padding-left: 1rem;
}
h1 {
  color: #ffe7aa;
  font-size: 2.25rem;
}
p {
  color: #9da6af;
  font-size: 0.875rem;
  font-weight: 400;
}
.btn-dance {
  background: #ed342b;
  color: #ffffff;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  height: 54px;
  line-height: 15px;
}
.btn-dance:hover {
  color: #ffffff;
}
img {
  width: 89%;
  margin: 0 auto;
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  h1 {
    font-size: 3.125rem;
  }
  p {
    font-size: 1rem;
  }
  .btn-dance {
    /* padding: 1rem 2rem; */
    height: 54px;
  }
  .ps-6 {
    padding-left: 6rem;
  }
}
</style>
