<template>
  <div class="home">
    <section id="top">
      <Header id="header" :scrolled="scrolled" @inClick="scrollPage" />

      <Intro class="" />
    </section>

    <section id="moreInfo" class="">
      <Whoweare />

      <OurProducts id="oProducts" />
    </section>

    <section id="payments">
      <Payments />
    </section>

    <section id="pricinDev">
      <Pricing />

      <Developers id="developer" />
    </section>

    <section id="security">
      <Security />
    </section>

    <section id="footer">
      <Footer />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Intro from "@/components/home/Intro.vue";
import Whoweare from "@/components/home/Whoweare.vue";
import OurProducts from "@/components/home/OurProducts.vue";
import Payments from "@/components/home/Payments.vue";
import Pricing from "@/components/home/Pricing.vue";
import Developers from "@/components/home/Developers.vue";
import Security from "@/components/home/Security.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",

  components: {
    Header,
    Intro,
    Whoweare,
    OurProducts,
    Payments,
    Pricing,
    Developers,
    Security,
    Footer,
  },

  data() {
    return {
      scrolled: false,
      scrollHeight: 0,
    };
  },

  mounted() {
    const sec = this.$route.query.sec;

    let elmnt;
    if (sec) {
      // got to the section selected
      switch (sec) {
        case "biopay":
        case "payhandle":
          elmnt = document.getElementById("oProducts");
          elmnt.scrollIntoView();
          break;
        case "developer":
          elmnt = document.getElementById("developer");
          elmnt.scrollIntoView();
          break;
      }
      this.scrolled = true;
    } else {
      elmnt = document.getElementById("top");
      elmnt.scrollIntoView();
    }

    window.onscroll = function () {
      setFixedTop();
    };

    // Get the header
    const header = document.getElementById("header");

    // Get the offset position of the navbar
    const sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const setFixedTop = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        this.scrolled = true;
      } else {
        header.classList.remove("sticky");
        this.scrolled = false;
      }
    };
  },

  methods: {
    scrollPage(sect) {
      const elmnt = document.getElementById(sect);
      elmnt.scrollIntoView();
    },
  },
};
</script>
<style scoped>
.home {
  height: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.home::before {
  content: "";
  background-image: url("../assets/images/bg-cubic.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
  opacity: 0.03;
}

#top {
  background: #0b2238;
}

#payments {
  background: #0b2238;
  background-image: url("../assets/images/abstract-lines.svg");
  background-repeat: no-repeat;
  background-position: top right;
}

#security {
  margin: 0 1rem;
  border-radius: 8px;
  background: #0b2238;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #top {
    /* padding-left: 6rem; */
    padding-right: 6rem;
    padding-top: 0.8rem;
  }

  #payments,
  #security {
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 0.8rem;
  }

  #moreInfo,
  #pricinDev {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
</style>
