import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "font-awesome/css/font-awesome.min.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { VueReCaptcha } from "vue-recaptcha-v3";

const app = createApp(App).use(store);

app.use(VueSweetalert2);
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GKey });

app.use(router).mount("#app");
