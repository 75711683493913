<template>
  <div class="px-3 mt-5 pt-3 pb-2">
    <div class="d-block d-lg-flex justify-content-between px-3 my-5 my-lg-2">
      <div class="col-12 col-lg-7 mb-0 mb-lg-3">
        <h2>Security and Safety</h2>
        <p class="mt-4">
          Our technology is constantly monitoring, evolving, and adapting in
          real-time to new forms of fraud as they arise.
        </p>

        <div class="mt-5">
          <ul>
            <li class="">
              <h6 class="mb-3">The Nigerian data protection certified</h6>
              <div class="d-flex">
                <img
                  src="@/assets/images/partners/nitda.svg"
                  alt="icons"
                  width="89"
                  height="89"
                  class="me-3 mt-"
                />
                <p>
                  This certificate confirms that appmart has Develop a Data
                  Protection Policy in line with the requirements of Nigerian
                  Data Protection Regulation agency.
                </p>
              </div>
            </li>

            <li class="">
              <h6 class="mb-3">Payment Card Industry Data Security Standard</h6>
              <div class="d-flex">
                <img
                  src="@/assets/images/partners/dss.svg"
                  alt="icons"
                  width="89"
                  height="89"
                  class="me-3 mt-1"
                />
                <p>Appmart products are PCI DSS compliants</p>
              </div>
            </li>

            <li class="">
              <h6 class="mb-3">NDPR Audit</h6>
              <div class="d-flex flex-center">
                <div class="nitda">
                  <img
                  src="@/assets/images/partners/NITDA_Audit_Compliant_Logo_june_2022.svg"
                  alt="icons"
                  width="79"
                  height="79"
                  class="me-3 mt-1"
                />
                </div>
                <p>
                  AppMart is NDPR Audit compliant.
                </p>
              </div>
            </li>
            <li class="mb-3">
              <h6>CBN Licenced</h6>
              <img src="@/assets/images/partners/cbn.jpeg" height="50" width="50" style="border-radius: 10px" alt="">
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12 col-lg-5 my-5 d-flex justify-content-end">
        <img
          class="img"
          src="@/assets/images/security.svg"
          alt="payment cards"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurProducts",

  data() {
    return {
      payments: [
        "Mobile Money",
        "Bank Transfer",
        "Bank Account",
        "POS",
        "USSD",
        "Visa QR",
      ],
    };
  },
};
</script>
<style scoped>
h2 {
  font-weight: bold;
  color: #ffe7aa;
  line-height: 38px;
  font-size: 1.875rem;
}
p {
  font-size: 1rem;
  color: #9da6af;
  align-self: center;
  text-align: justify;
}

.flex-center{
  align-items: center;
}

.nitda{
  background: #fff !important;
  height: 89px;
  width: 89px;
  margin-right: .7rem;
  border-radius: 10px;
}
.img {
  width: 100%;
  margin: 0 auto;
}
ul {
  padding-inline-start: 0;
}
li {
  list-style: none;
  color: #ffffff;
  margin-top: 2.3rem;
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
</style>
