<template>
  <div class="about">
    <section id="top">
      <Header id="header" :scrolled="true" />
    </section>

    <section id="moreInfo" class="">
        <div class="content-wrap">
            <h4 class="heading">TERMS OF USE</h4>
            <p>Last Updated: May, 2022</p>
            <h6>ACCEPTANCE OF TERMS</h6>
            <p>
                The services that AppMart provides to its users are subject to the following Terms of Use (TOU). Please read them carefully before using the Service. 
            </p>
            <p>AppMart reserves the right to update the Terms of Use, at any time, without notice to the Users. The most current version of this TOU can be viewed by clicking on the “Terms of Use” hypertext link located at the bottom of our web pages. </p>
            <p>
                This Agreement, which incorporates by reference other provisions applicable to the use of AppMart.ng, including, but not limited to, supplemental terms and conditions set forth hereof ("Supplemental Terms") governing the use of certain specific material contained in AppMart.ng sets forth the terms and conditions that apply to use of AppMart.ng by User. By using AppMart.ng (other than to read this Agreement for the first time), User agrees to comply with all of the terms and conditions hereof. The right to use AppMart.ng is personal to User and is not transferable to any other person or entity. User is responsible for all use of User's Account (under any screen name or password) and for ensuring that all use of User's Account complies fully with the provisions of this Agreement. User shall be responsible for protecting the confidentiality of User's password(s), if any.
            </p>
            <p>AppMart shall have the right at any time to change or discontinue any aspect or feature of AppMart.ng, including, but not limited to, content, hours of availability, and equipment needed for access or use.
All references in these Terms of Use to we, us, or our shall refer to AppMart and the references to you or your shall refer to the Users of the Service.</p>
<h6>ABOUT US</h6>
<p>
    AppMart is a CBN licensed Payment Solution Service Provider (PSSP). We are a one stop shop for payments and collections in Africa and the rest of the world. We provide a financial services platform that is easy and convenient to use for all stakeholders in the financial space. The enabling environment created by Appmart will ensure business growth and sustainability.
</p>
<h6>DESCRIPTION OF SERVICES</h6>
<p>Through its Web property, AppMart provides User with access to a variety of payments and collections resources, including download areas, communication forums and product information (collectively "Services"). The Services, including any updates, enhancements, new features, and/or the addition of any new Web properties, are subject to the TOU.</p>
<h6>AGE</h6>
<p>You must be 18years or older to use the services provided by AppMart unsupervised. If you are under the age of 18, you may only use the service in conjunction with, and under the supervision of your parents or guardians.</p>
<h6>VERIFICATION OF DATA</h6>
<p>
    By using this service you authorize us to carry out all necessary inquiries to verify the information you submit to us on any database to which we have access.
You authorize us to communicate with you concerning any ambiguity or other reason, in order that such inquiries can be fairly and diligently carried out.
</p>
<h6>EQUIPMENT</h6>
<p>User shall be responsible for obtaining and maintaining all telephone, computer hardware, software and other equipment needed for access to and use of AppMart.ng and all charges related thereto</p>
<h6>ACCEPTABLE USE</h6>
<p>
    As a User, you must not use this service in any way that causes or may cause damage to the website or impairment of the availability or accessibility of the service; or in any way connected to any unlawful, illegal, fraudulent, or harmful purpose or activity.
You must not use this service to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit, or other malicious computer software.
You must not use this service for any purposes related to marketing without our express written consent.
</p>
<h6>GENERAL USE</h6>
<p>In order to access certain features of our service, you may be required to provide information about yourself (such as identification or contact details) as part of the registration process for the purpose of creating an account or as a part of your continued use of the service. You agree that any information you give to us will always be accurate, correct, and up to date.</p>
<p>You agree to use the service for purposes that are permitted by (a) these Terms of Use, and (b) any applicable law, regulation, or generally accepted practices or guidelines in the relevant Nigeria and other relevant jurisdictions (including any laws regarding the export of data or software to and from Nigeria or other relevant countries).</p>
<p>
You agree not to access (or attempt to access) the service by any means other than through the interface that is provided by us. You specifically agree not to access (or attempt to access) any part of the service through any automated means (including the use of scripts or web crawlers) and you shall ensure that you comply with the instructions set out in any robots.txt file present on the Web application and API.
You agree that you will not engage in any activity that interferes with or disrupts the service (including the servers and networks which are connected to the Web Application or API).
</p>
<p>
You agree that you will not reproduce, duplicate, copy, republish, upload, post, transmit, distribute, modify, sell, trade, or resell the Web Application or API, or any portion, feature, or content thereof, for any purpose; provided that you may download one copy of the materials on the website on any single computer for your non-commercial use only and you may print one copy of the materials for your non-commercial use only; further, provided that you keep intact all copyright and other proprietary notices on any downloaded or printed materials.
</p>
<p>
We reserve the right to modify or discontinue, temporarily or permanently, your access to the service, or your account with or without notice.
</p>
<p>
You agree that you shall by no means store or create an alternative database using the data or response received from AppMart Web Application or API
You as a user warrant that you shall be solely responsible and liable for Know-Your-Customer (KYC) and Know-Your-Customer Business (KYB) details of all persons you introduce to this service and in any event of any fraudulent card transactions and other transactions, you agree to indemnify and defend AppMart; and also, you agree to pay for damages, fees, charges and cost awarded against us from a third party`s claim. 
</p>

<h6>OTHER OBLIGATIONS</h6>
<p>
    Only humans can access and make use of this service, therefore all accounts that are registered by bots or other automated methods are not permitted.
Your account can only be used by one person (you) - a single account shared by multiple people is not permitted.
</p>
<p>
You agree and understand that you are responsible for maintaining the confidentiality and security of your account and password. AppMart cannot and will not be liable for any loss or damage suffered by you from your inability to comply with this security obligation.
If you become aware of any unauthorized use of your account or password, kindly notify us immediately.
</p>
<p>
    You may be charged by your service provider for internet access on your device when using our service.
</p>
<p>
    You agree that you shall by no means store or create an alternative database using the data or response received from this service. Where we notice that an alternative database has been created, we reserve the right to terminate your access to the service and to take up legal action against you.
</p>
<p>
    For third party software, you agree that you have the required license to use any third party`s software
Your use of this service does not and will not constitute a breach of any existing law, regulation, patent, copyright, or other intellectual property in Nigeria or countries of domicile and operation or any relevant jurisdiction.
</p>
<p>
    You must be duly registered, licensed (company), and must have the full capacity, regulatory approvals, and corporate authorization to make use of this service.
Your use of this service shall not constitute any illegal or fraudulent activity or purpose.
You agree that you will be solely responsible for all activities that occur under your account.
You agree that you are solely responsible (we have no responsibility to you or to any third-party) for any breach of your obligations under these Terms of Use and for the consequences (including any loss or damage which we may suffer) of any such breach.
</p>
<h6>SPAM AND SCAMS</h6>
<p>
    You agree that you shall not use this service to attract, lure, deceive, commit fraud or illegally obtain payment of any sort from others by any unjustified means, for example, where you use this service as a money-making program on any site. Where your account is found to be doing so, such account will be terminated. You, therefore, render yourself to legal enforcement agencies.
</p>
<h6>REGISTRATION, ACCOUNTS, AND LOGINS</h6>
<p>
    This service requires you to register and create an account. You represent and warrant that all information you provide in connection with the creation of an account (Account) is accurate and true. You agree that, if any information changes, you will update your Account to maintain accurate information.
When you create an Account, you must also create a Login Password for security purposes. You agree that you will not disclose your Login Password to any other person. You agree that you will not transfer, sub-license, sell, or assign your rights in your Account to any other person.
</p>
<h6>
    PROPRIETARY RIGHTS
</h6>
<p>
    All content included on the service (Website, Web Application, and API), such as trademarks, copyrights, service marks, trade names, text, graphics, logos, icons, button icons, images, data compilations, domain names, and software, is the property of AppMart and its software suppliers. These rights are protected by Nigerian and International copyright laws. Nothing on this service should be construed as granting any license except expressly provided therein.
</p>
<h6>VIOLATION OF THESE TERMS OF USE</h6>
<p>
    We reserve the right to disclose any information provided where such disclosure is necessary for any investigation or complaint received in your violation of these Terms of Use. We also reserve the right to disclose such information where it is intended to bring an action against such a person.
You agree that we reserve the right and at our own discretion to terminate your access to this service without giving you any prior notice where you breach any of the Terms of Use and guidelines. Upon the breach of these Terms, you agree that we can bring an action for the recovery of damages, injunction or any other equitable remedy depending on the circumstances. These remedies are in addition to other remedies that may exist in law and equity.
</p>
<h6>TERMINATION</h6>
<p>We may at our discretion and without reasons terminate or suspend your use of this service when we reasonably believe that;</p>
<ul>
    <li>you have violated these Terms of Use or other relevant policy</li>
    <li>we are directed by law enforcement or other government agencies to do so</li>
    <li>we believe that your actions may cause financial loss or legal liability for you, our users or to us</li>
    <li>we suspect that you have engaged in suspicious or fraudulent activity</li>
    <li>you breached or attempt to breach the security of the application</li>
    <li>an unexpected technical issue or problem occurs and our provision of the service is no longer viable</li>
</ul>
<p>
    The termination of your access to the service or our provision of the service shall not affect any term which by implication is meant to be enforceable against you after such termination.
</p>
<h6>INDEMNIFICATION</h6>
<p>
    us harmless) from any claim arising out of your use, or inability to use the service, or your violation of any applicable law.
You also agree to indemnify us with respect to any negligent acts or omissions or willful conducts done by your employees or agents while using the service.
</p>
<h6>
    LIMITATIONS OF LIABILITY
</h6>
<p>
    We will not be liable for any damages of any kind arising from your use, or inability to use the service whether in contract, tort, or otherwise. <br>
We shall also not be liable for the following;
</p>
<ul>
    <li>malfunctions in communications facilities which cannot reasonably be considered to be under our control and that may affect the accuracy or timeliness to provide our services.</li>
    <li>any losses or delays in transmission of messages or data arising out of the use of any internet or telecommunications service provider or caused by any browser or other software which is not under our control, or errors on the website or with the service caused by incomplete or incorrect information provided to us by you or a third party.</li>
    <li>where we modify, suspend or discontinue, temporarily or permanently, the service or any part of it, with or without notice.</li>
    <li>all actions or inactions of a third party not acting on our instructions neither shall we be liable for any action not traceable to us.</li>
    <p>
        No liability shall lie against us more than one (1) year if no written notice is received by us of a claim after the accrual of the cause of such liability. Where a jurisdiction does not allow the exclusion of certain warranties or disclaimers or the limitation or exclusion of warranties or liabilities, in such case our liability shall be the minimum permitted under such applicable law.
    </p>
</ul>

<h6>
MODIFICATION OF TERMS
</h6>
 
<p>
    You agree that we reserve the right to alter, modify and change these terms. AppMart shall have the right at any time to change or modify the terms and conditions applicable to User's use of AppMart.ng, or any part thereof, or to impose new conditions, including, but not limited to, modifying or adding fees and charges for use.
Unless otherwise agreed by us in writing, you agree that such changes and modifications may be made solely by posting the revised Terms of Use or other policies or guidelines on the website and indicating thereof the date it was last updated. You agree to such a form of notification of changes and modifications and waive any right to receive individual notice of such changes or modifications. Any change or modification will be effective immediately upon posting the revisions on the website.
Your continued use of the Service following the posting of any changes or modifications will constitute your acceptance of such changes or modifications. Therefore, you should review these Terms of Use and applicable policies or guidelines whenever you access this service in order to determine if such terms, policies or guidelines have changed, and to understand the terms, policies, and guidelines that apply to your use of the service.
</p>

<p style="font-weight: bold">
    Disclaimer <br>
    WE DO NOT WARRANT OR GUARANTEE THAT YOUR USE OF THIS SERVICE SHALL BE WITHOUT FAULT OR ERRORS, WE HEREBY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED OR OTHERWISE WITHOUT LIMITATION THAT THIS SERVICE IS FREE FROM DEFECT OR USE FOR A PARTICULAR PURPOSE, YOU BEAR THE RISK AS TO USING THIS SERVICE FOR ANY PURPOSE AND QUALITY. WE SHALL NOT AND WILL NOT BE LIABLE FOR ANY RISK SUFFERED BY YOUR HARDWARE OR SOFTWARE, BE IT THAT THEY BECOME DELETED OR CORRUPTED OR THAT SOMEONE UNAUTHORIZED GAINED ACCESS TO YOUR INFORMATION. WE DO NOT GUARANTEE THAT THE SERVICES PROVIDED UNDER THIS PLATFORM SHALL BE FREE FROM VIRUSES, ATTACKS, CORRUPTION, HACKING, AND INTRUSION FROM THIRD PARTIES. WE SHALL NOT BE LIABLE FOR ANY DATA CHARGES INCURRED BY YOU DURING THE USE OF THIS SERVICE. EXCEPT WHERE IT IS PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGE RESULTING FROM YOUR BREACH OF THIS TERMS OF USE.

</p>
<h6>MISCELLANEOUS</h6>
<p>
    These Terms constitute the entire agreement between you and us and it supersedes any other communication or Terms. These Terms are governed by the laws of Nigeria and only courts in Abuja, FCT have jurisdiction thereof. 
In the event of a dispute between you and us for any violation of the terms of use, parties shall settle the dispute amicably and where parties are unable to do this, they shall resort to Arbitration; the sitting for the Arbitration shall be Abuja, Nigeria.
The headings in this document are for convenience only and are not intended to have any legal effect nor purport to be complete or accurate descriptions of the content thereof and shall not be used to interpret the provisions of this document.
In the event, any of the aforesaid Terms of Use shall be rendered/held to be unenforceable due to any regulatory changes, the remaining Terms of Use shall be unimpaired, and the unenforceable Term shall be replaced by such enforceable Term as may come closest to the intention underlying the unenforceable Term.
</p>
<h6>
    ANTI-CORRUPTION
</h6>
<p>
    You hereby warrant that, you have not and undertake that you will not offer, promise, give, authorize, solicit or accept any undue pecuniary or other advantages of any kind (or imply that you will not do any such thing at any time in the future) in any way connected with the Terms of Use and that you have taken reasonable measures to prevent third parties, subject to your control or determining influence, from doing so.
</p>
<p>
    You also agree that you shall comply with all applicable anti-bribery and anti-corruption Laws in Nigeria and other any relevant jurisdiction.
</p>










        </div>
    </section>

    <section id="footer">
      <Footer />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "About",

  components: {
    Header,
    Footer,
  },

  data() {
    return {
      scrollHeight: 0,
    };
  },

  mounted() {
    window.onscroll = function () {
      setFixedTop();
    };

    // Get the header
    const header = document.getElementById("header");

    // Get the offset position of the navbar
    const sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const setFixedTop = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  },
};
</script>
<style scoped>
.about {
  height: 100%;
}
.content-wrap {
    margin-top: 2rem;
    text-align: justify;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.heading{
    margin-bottom: 2rem;
    text-align: center;
}
.about::before {
  content: "";
  background-image: url("../assets/images/line-cc.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
  opacity: 0.03;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #moreInfo {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
</style>
