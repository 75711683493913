<template>
  <div class="px-3 mt-5">
    <ol>
      <div class="d-block d-lg-flex justify-content-between px-3">
        <div class="col-12 col-lg-12 mt-5 mt-lg-0">
          <h1 class="mt-4">
            Third Party Information Security Policy
          </h1>
          <li>Information Security Due Diligence </li>
          <p class="mt-4">
            Information security due diligence refers to assessing third-party security controls against Appmart
            requirements to ensure their effectiveness and identify potential security risks before contractual
            engagement.
            An authorized business area/department must complete a due diligence exercise for all third parties who will
            access or could potentially access Appmart information.
            Due diligence must be completed before the transfer of or access to Appmart information or connection to
            Appmart's systems by the third party or its staff.
            The branch Managers engaging with a third party must ensure that a due diligence has been conducted. The
            process
            must be described in a formal third-party risk assessment report and include a review of the following:

          </p>
        </div>
      </div>

      <div class="d-block d-lg-flex flex-row-reverse justify-content-between px-3">
        <div class="col-12 col-lg-12">
          <ul class="">
            <li>Sensitivity of information and systems to be accessed or managed. </li>
            <li>Locations from where access will originate. </li>
            <li>Security controls implemented. </li>
            <li>Type of service provided</li>
            <li>Competency of the personnel. </li>
            <li>Legal and regulatory requirements.</li>
            <li>Level of control over staff or systems employed by the third party. </li>
            <li>Profile and reputation of the third party; and </li>
            <li>Involvement of sub-contractors to the third party. </li>
          </ul>

          <p class="mt-4">
            The information security controls implemented by a Third Party must be assessed as part of the Due Diligence
            exercise.
          </p>
          <p class="mt-4">
            Remedial actions to mitigate identified risks for all third parties, must be identified and implemented. The
            due
            diligence must be updated after an agreed period to determine if the actions were completed and the risk
            associated with the third party has been reduced to an acceptable level, prior to any contractual engagements.
          </p>

          <li>Security in Contracts </li>
          <p class="mt-4">
            Contracts with third parties accessing Appmart information must clearly state their obligations in terms of
            protecting information assets and include the following clauses:
          </p>
          <ul class="">
            <li>The third party must adhere to relevant information security policies and procedures. </li>
            <li>A third party must be provided with a copy of Appmart Information Security Policy and acknowledge receipt,
              understanding and adherence to the policy. </li>
            <li>A requirement for third-party employees working on-site to be provided with a copy of Appmart Acceptable
              Use Policy and acknowledge receipt, understanding and adherence to the policy. </li>
            <li>If the third party has access to Appmart physical/IT assets, requirements for asset protection must be
              incorporated. </li>
            <li>Physical entry/exit and access mechanisms to facilities </li>
            <li>A requirement for the third party to request any additional Appmart security documents or the information
              it requires to implement the relevant information security policies. </li>
            <li>A requirement for the third party to adhere to all relevant local and international laws and regulations
              regarding the use and protection of information. </li>
            <li>A third party must inform Appmart of any changes to its internal structure, operating environment or
              ownership that may impact its services or the security of information. </li>
            <li>A "right to audit" allows Appmart to assess the third party's security environment on an ongoing basis.
            </li>
            <li>Conditions for contract termination without penalty to Appmart should the third party not meet security
              requirements and pose a risk. </li>
            <li>A right to revoke the access of third-party employees to Appmart systems, information, or sites in the
              event of a suspected security breach.</li>
            <li>A confidentiality/non-disclosure agreement which describes information handling requirements in line with
              Appmart Information Classification policy. </li>
            <li>Contracts must be valid for a specified period. Contracts must be reviewed on renewal or where changes are
              made to the contract. The branch Manager engaging with a third party must ensure that a formal termination
              process is followed at the end of a contract period, which must include: </li>
            <li>Return of all Appmart information assets, equipment and ID cards provided to them during their contract
              period, and Removal of access to all systems. </li>
            <li>A requirement for third parties to handle Appmart information in line with the classification. Third
              parties must assign such information, a classification that is of equal or higher security level </li>
            <li>Disciplinary actions in case of non-compliance or compromise of Information Security </li>
            <li>Channel of escalation in case of issues/problems </li>
            <li>Clear indication of Intellectual Property (IP) rights if any </li>
            <li>Security requirements to be followed in case the third party is sub-contracting activities that involve
              handling Appmart information </li>
            <li>Clause for renegotiation of agreements if the security/business/legal/ regulatory requirements of Appmart
              change.</li>
          </ul>

          <li class="mt-4">Security in Contracts </li>

          <p class="mt-4">
            Formal third-party risk assessments for all high risk third parties must be updated at least every 12 months
            if the contract period is longer than one year. If the risk rating increases to medium or high, immediate
            mitigation actions and timelines must be agreed with the third party. If agreed mitigation actions are not
            implemented or the risk is not addressed within the agreed period, the contract must be terminated.
          </p>

          <p class="mt-4">In addition, further due diligence must be considered where: </p>

          <ul class="">
            <li>An information security incident has resulted from a third-party error or negligence.</li>
            <li>An investigation is being undertaken of a potential third-party breach of policies; and </li>
            <li>Major changes have taken place in the structure, operating environment, ownership of the third party.
            </li>
          </ul>

          <li class="mt-4">Managing Changes to Third Party services </li>

          <p class="mt-4">
            Changes to third party contracts or service level agreements must be managed through a formal change
            management process. The change process must be initiated through the issue of a request from Appmart or the
            third party to change a service being delivered through an existing contract.
            This request must be documented in a formal Change Request, and provide the following information:
          </p>

          <ul class="">
            <li>Title and number of the original contract. </li>
            <li>Name of the third party.</li>
            <li>Name of the change.</li>
            <li>The originator and date of the request for the change.</li>
            <li>The reasons for the change.</li>
            <li>Full details of the change including any specifications.</li>
            <li>The cost applicable to the change.</li>
            <li>The timetable for the implementation of the change. </li>
            <li>A schedule of payments if appropriate. </li>
            <li>Assessment of the impact of the change. </li>
            <li>A unique sequential number of the Change request; and </li>
            <li>The date on which the Change request shall expire. </li>
          </ul>

          <p class="mt-4">
            Third parties must inform Appmart of changes undertaken within their environment that may impact on the
            delivery of services. These changes must be managed by the third party in accordance with a formal change
            management process and in alignment with Appmart Information Security policy and other contractual
            requirements.
          </p>

          <li class="mt-4">Third Party Cloud Computing Services </li>

          <p class="mt-4">
            The use of third-party cloud computing services is subject to the same controls as other third-party services.
            The use of cloud computing services is currently limited and based on an assessment of the risks associated
            with the service and the security controls provided by the service provider.
          </p>

          <p class="mt-4">
            Information placed in the cloud must be protected by appropriate security measures, as required by the
            associated risk. The use of cloud computing services must be supported by a contract which covers all clauses
            that apply to policy third-party contracts. In addition, the following special provisions related to the use
            of cloud services must be included:
          </p>

          <ul>
            <li>Providing a secure authentication service. </li>
            <li>Restricting access to authorized users. </li>
            <li>Implementing appropriate malware monitoring and protection solutions. </li>
            <li>Sharing security-related information about unusual or malicious activity. </li>
            <li>Providing advance notification prior to any changes being made to the way service is delivered; and </li>
            <li>Take into consideration any legal or regulatory requirements where some countries do not allow
              transactions or information to leave their borders. </li>
          </ul>

          <p class="mt-4">
            Information handling requirements as described in the Information Classification Scheme apply to information
            used in the cloud:
          </p>

          <ul>
            <li>Information classified as CONFIDENTIAL may not be used in a cloud environment and if use, it must be in a
              controlled manner.</li>
            <li>Information classified as INTERNAL USE can be used in the cloud </li>
          </ul>

          <p class="mt-4">
            The use of cloud-based file hosting facilities is subject to the following requirements:
          </p>

          <ul>
            <li>Appmart employees using this service must ensure that their account passwords meet Appmart's Password
              Policy.</li>
            <li>All files uploaded to file hosting services must first be password protected and encrypted.</li>
            <li>The password used to encrypt the file must meet Appmart password complexity requirements and be provided
              to the recipient in a secure manner through a different communication channel.</li>
            <li>Appmart employees leaving the company must return any data to Appmart. </li>
            <li>When sharing information with a third party, the shared folder on the file hosting service must be unique
              to that third party and folders must not be shared. </li>
            <li>Where cloud-based hosting services are deployed, employees must take in to account those certain countries
              prohibits the crossing of personal information across country boundaries. </li>
          </ul>

          <li class="mt-4">Violation</li>

          <p class="mt-4">
            The Compliance to this policy is managed by the Chief Information Security Officer. Exceptions from any
            controls identified in this policy must be justified and can only be implemented upon approval obtained
            through relevant authorities.
          </p>

          <p class="mt-4">
            Non-compliance may result in disciplinary action. 
          </p>
        </div>
      </div>
    </ol>
  </div>
</template>
<script>
// import partners from "../../assets/data/partners.json";
export default {
  name: "Whoweare",
  data() {
    return {
      // partners,
    };
  },
  methods: {
    gotoPolicy() {
      this.$router.push({
        path: `/policy`,
      });
    }
  },
};
</script>
<style scoped>
img {
  width: 82%;
  margin: 0 auto;
}

h1 {
  font-weight: bold;
  color: #0b2238;
  line-height: 1.2;
  font-size: 2rem;
}

p {
  font-size: 1rem;
  color: #9da6af;
  text-align: justify;
}

li {
  font-size: 1rem;
  color: #9da6af;
  text-align: justify;
}

a {
  text-decoration: none;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  h1 {
    font-size: 3.125rem;
  }
}</style>
