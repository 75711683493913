<template>
  <div class="px-3 mt-5">
    <div class="d-block d-lg-flex justify-content-between px-3 mt-5">
      <div class="col-12 col-lg-4 my-5 d-flex justify-content-end">
        <img src="@/assets/images/payhandle.png" alt="pay handle" />
      </div>

      <div class="col-12 col-lg-7 mt-0 mt-lg-5">
        <h6>Our Products</h6>
        <h2 class="mt-4">Better payments, unlimited opportunities</h2>
        <p class="mt-4">
          Appmart has products tailored to suite the modern ways of transacting
          businesses for online and in person transactions, marketplace etc. Our
          products are easy to integrate for merchants, enterprises and
          individuals. You can start accepting payments in less than 20mins. No
          cumbersome customer check as all check is automated and web-based.
        </p>

        <div class="mt-5">
          <div>
            <h3>
              <span class="shade shade1"></span>
              <span class="ms-4">BioPay</span>
            </h3>
            <p class="ms-4">
              Makes it easy to make and accept payments from customers anywhere
              in Nigeria and Africa. Seamless integrations by Merchants,
              E-commerce platforms, SMEIS, individuals etc.
            </p>
          </div>

          <div class="mt-4">
            <h3>
              <span class="shade shade2"></span>
              <span class="ms-4">PayHandle</span>
            </h3>
            <p class="ms-4">
              Is a P2P payment platform targeting both the banked and the
              unbanked in the country. Instant payment and accept transfers from
              both friends and general public with or without bank accounts.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurProducts",
};
</script>
<style scoped>
h2 {
  font-weight: bold;
  color: #0b2238;
  line-height: 38px;
  font-size: 1.875rem;
}
h6 {
  color: #0b2238;
  font-size: 1rem;
}
h3 {
  color: #0b2238;
  font-size: 1.5rem;
  position: relative;
}
h3 .shade {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
h3 .shade.shade1 {
  background: rgba(34, 90, 255, 0.1);
}
h3 .shade.shade2 {
  background: rgba(180, 248, 228, 0.377);
}
p {
  font-size: 1rem;
  color: #9da6af;
  text-align: justify;
}
img {
  width: 92%;
  margin: 0 auto;
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
</style>
