<template>
  <div class="px-3 mt-5">
    <div class="d-block d-lg-flex justify-content-between px-3 my-5">
      <div class="col-12 col-lg-6 d-flex justify-content-center">
        <img src="@/assets/images/trusted.png" alt="appmart office" />
      </div>

      <div class="col-12 col-lg-6 mt-5 mt-lg-0">
        <h6>Trusted by over 3,000 businesses</h6>
        <h2 class="mt-4">We deliver best-in-class payment performance.</h2>
        <p class="mt-4">
          By using our payment technology, we deliver on our mission of creating
          a world of money without borders and bring lower costs and greater
          ease to our customers.
        </p>

        <div class="d-flex flex-wrap mt-5">
          <div class="mx-3 mb-4" v-for="(p, i) in partners" :key="i">
            <img
              class="partner"
              :src="require(`@/assets/images/partners/${p.image}.png`)"
              :alt="p.name"
              :title="p.name"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import partners from "../../assets/data/partners.json";
export default {
  name: "Whoweare",
  data() {
    return {
      partners,
    };
  },
};
</script>
<style scoped>
img {
  width: 89%;
  margin: 0 auto;
}
h2 {
  font-weight: bold;
  color: #0b2238;
  line-height: 38px;
  font-size: 1.875rem;
}
h6 {
  color: #0b2238;
  font-size: 1rem;
}
p {
  font-size: 1rem;
  color: #9da6af;
}
.partner {
  max-width: 5rem;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.partner:hover {
  filter: none;
  -webkit-filter: grayscale(0);
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .partner {
    max-width: 7rem;
  }
}
</style>
